import {useState, createRef} from 'react';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';

import {requestPasswordReset} from '../state/accountApi';
import {Page} from '../page';
import TurnstileWidget from '../components/TurnstileWidget';
import LoginLayout from '../layouts/LoginLayout';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/joy';

export function RequestPasswordRequestPage() {
    const emailRef = createRef<HTMLInputElement>();
    const [cfToken, setCfToken] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (event: {preventDefault: () => void}) => {
        event.preventDefault();
        const email = emailRef.current?.value;
        if (!email) return;
        await requestPasswordReset(email, cfToken);
        setResetSent(true);
        await new Promise((resolve) => setTimeout(resolve, 10000));
        navigate(Page.Login);
    };

    return (
        <LoginLayout title="Reset Password">
            <Typography sx={{textAlign: 'center', py: 2}}>
                Enter your email address, we will send you a link to reset the password.
            </Typography>
            {resetSent ? (
                'Password reset link was sent to your email.\n' +
                'In case this email was known.'
            ) : (
                <form onSubmit={onSubmit}>
                    <label>Email</label>
                    <Input id="email" slotProps={{input: {ref: emailRef}}} />
                    <Button type="submit" sx={{marginTop: 2, width: '100%'}}>
                        Reset
                    </Button>
                    <TurnstileWidget setCfToken={setCfToken} />
                </form>
            )}
        </LoginLayout>
    );
}

export default RequestPasswordRequestPage;
