import {useEffect, useState} from 'react';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';

import {API_URL, login} from '../state/accountApi';
import {Link} from '@mui/joy';
import Typography from '@mui/joy/Typography';
import {Page} from '../page';
import LoginLayout from '../layouts/LoginLayout';
import {useNavigate} from 'react-router-dom';
import {GoogleLogo} from '../icons/google-logo';
import {Separator} from '../components/Separator';

const setElementValueIfExists = (setter: (value: any) => void, value: any) => {
    if (value) {
        setter(value);
    }
};

function Login({
    setIsLoggedIn,
    onError,
}: {
    setIsLoggedIn: (value: boolean) => void;
    onError: (m: string) => void;
}) {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [actionId, setActionId] = useState('');
    const [ref, setRef] = useState('');
    const [sso, setSSO] = useState('');
    const [sig, setSIG] = useState('');
    const [SAMLRequest, setSAMLRequest] = useState('');

    const onSubmit = (event: {preventDefault: () => void}) => {
        event.preventDefault();
        login({
            email,
            password,
            sso,
            sig,
            ref,
            actionId,
            SAMLRequest,
        })
            .then((loggedIn) => {
                if (loggedIn) {
                    setIsLoggedIn(true);
                    // load action if there is an actionId to trigger showing the ConfirmAction Dialog...
                    if (actionId) {
                        navigate(Page.ActionConfirm);
                    }
                } else {
                    setIsLoggedIn(false);
                }
            })
            .catch((e) => {
                onError(e.message);

                setIsLoggedIn(false);
            });
    };
    useEffect(() => {
        const params = new URL(document.location.href).searchParams;
        const source = params.get('ref');
        const ssoValue = params.get('sso');
        const sigValue = params.get('sig');
        const actionIdValue = params.get('actionId');
        const SAMLRequestValue = params.get('SAMLRequest');
        const validSources = [
            'discourse',
            'market',
            'cloud',
            'cloud-staging',
            'editor',
            'story-machine',
            'story-machine-staging',
            'wordpress',
        ];
        if (source && validSources.includes(source)) {
            // append login values to login form
            setElementValueIfExists(setRef, source);
            setElementValueIfExists(setSSO, ssoValue);
            setElementValueIfExists(setSIG, sigValue);
            setElementValueIfExists(setActionId, actionIdValue);
            if (SAMLRequestValue) {
                const encoded = btoa(SAMLRequestValue || '');
                setElementValueIfExists(setSAMLRequest, encoded);
            }
            const googleHref = document.getElementById('google-oauth');
            if (googleHref && (sso || sig || actionId || SAMLRequest)) {
                (googleHref as HTMLLinkElement).href += '?ref=' + source;
                (googleHref as HTMLLinkElement).href +=
                    '&sso=' +
                    sso +
                    '&sig=' +
                    sig +
                    '&action=' +
                    actionId +
                    '&SAMLRequest=' +
                    SAMLRequest;
            }
        }
    }, [actionId, sig, sso, SAMLRequest]);

    return (
        <LoginLayout title="Log in">
            <form onSubmit={onSubmit}>
                <Input name="ref" id="ref" style={{display: 'none'}}></Input>
                <Input name="sso" id="sso" style={{display: 'none'}}></Input>
                <Input name="sig" id="sig" style={{display: 'none'}}></Input>
                <Input name="SAMLRequest" id="sig" style={{display: 'none'}}></Input>
                <Input
                    name="actionId"
                    id="actionId"
                    style={{display: 'none'}}
                    onChange={(evt) => {
                        setActionId(evt.target.value);
                    }}
                    value={actionId}
                ></Input>
                <label>Email</label>
                <Input
                    value={email}
                    onChange={(evt) => {
                        setEmail(evt.target.value);
                    }}
                    id="email"
                />
                <label>Password</label>
                <Input
                    value={password}
                    type="password"
                    onChange={(evt) => {
                        setPassword(evt.target.value);
                    }}
                    id="password"
                />
                <Button
                    type="submit"
                    onClick={onSubmit}
                    color="primary"
                    sx={{marginTop: 1, width: '100%'}}
                >
                    Log in
                </Button>
            </form>
            <Typography sx={{textAlign: 'right'}}>
                <Link onClick={() => navigate(Page.ResetPasswordRequest)}>
                    Forgot password?
                </Link>
            </Typography>
            <Separator label="or" />
            {/* FIXME replace with proper API url */}
            {/* FIXME Add missing google icon here */}
            <Button color="neutral">
                <Link
                    href={API_URL + '/auth/google'}
                    id={'google-oauth'}
                    sx={{
                        color: 'white',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                    }}
                >
                    <GoogleLogo /> Login with Google
                </Link>
            </Button>
            <Typography sx={{pt: 5}}>
                Don't have an account yet?{' '}
                <Link onClick={() => navigate(Page.Signup)}>Sign up.</Link>
            </Typography>
        </LoginLayout>
    );
}

export default Login;
