import {useState} from 'react';
import Checkbox from '@mui/joy/Checkbox';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';

import TurnstileWidget from '../components/TurnstileWidget';
import {createUser} from '../state/accountApi';
import {Page} from '../page';
import LoginLayout from '../layouts/LoginLayout';
import {useNavigate} from 'react-router-dom';

function SignUp({onError}: {onError: (message: string) => void}) {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [receiveNewsletter, setReceivedNewsletter] = useState(false);

    const [company, setCompanyName] = useState('');
    const [lastName, setLastName] = useState('');

    const [firstName, setFirstName] = useState('');

    const [, setSignupMessage] = useState('');
    const [cfToken, setCfToken] = useState('');

    const onSubmit = async (event: {preventDefault: () => void}) => {
        event.preventDefault();
        createUser(
            firstName,
            lastName,
            company,
            receiveNewsletter,
            email,
            password,
            cfToken
        ).then(({message}) => {
            if (message) {
                setSignupMessage(message);
                onError(message);
                navigate(Page.Login);
            }
        });
    };

    return (
        <LoginLayout title="Create Account">
            <form onSubmit={onSubmit}>
                <label>Firstname</label>
                <Input
                    value={firstName}
                    onChange={(evt) => {
                        setFirstName(evt.target.value);
                    }}
                />
                <label>Lastname</label>
                <Input
                    value={lastName}
                    onChange={(evt) => {
                        setLastName(evt.target.value);
                    }}
                />
                <label>Company</label>
                <Input
                    value={company}
                    onChange={(evt) => {
                        setCompanyName(evt.target.value);
                    }}
                />
                <label>Email</label>
                <Input
                    value={email}
                    onChange={(evt) => {
                        setEmail(evt.target.value);
                    }}
                />
                <label>Password</label>
                <Input
                    value={password}
                    type={'password'}
                    onChange={(evt) => {
                        setPassword(evt.target.value);
                    }}
                />
                <Checkbox
                    label="Receive Newsletter"
                    checked={receiveNewsletter}
                    onChange={(value) => {
                        setReceivedNewsletter(value.target.checked);
                    }}
                />
                <TurnstileWidget setCfToken={setCfToken} />
                <Button type="submit" onClick={onSubmit} sx={{width: '100%'}}>
                    Signup
                </Button>
            </form>
        </LoginLayout>
    );
}

export default SignUp;
