import React, {useEffect, useState} from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import {createApiToken, deleteApiToken, getApiTokens, APIToken} from '../state/accountApi';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import PageLayout from '../layouts/PageLayout';

const ApiToken = (props: {token: APIToken; deleteCallback: (id: string) => void}) => {
    const token = props.token.token;
    return (
        <ListItem className="grid grid-cols-5" key={props.token._id}>
            <div className="col-span-5">{props.token.description || '-'}</div>
            <div className="col-span-3">
                <div className="w-full">Last 10 chars *******{token}</div>
                <div className="w-full">Created: {props.token.createdAt}</div>
                <div className="w-full">Expires: {props.token.expireAt || '-'}</div>
            </div>

            <IconButton
                className="col-start-5"
                onClick={() => props.deleteCallback(props.token._id)}
                size="sm"
            >
                <DeleteRoundedIcon />
            </IconButton>
        </ListItem>
    );
};

const CreateTokenForm = ({onCreate}: {onCreate: (newApiToken: APIToken) => void}) => {
    const [expirationDate, setExpirationDate] = useState('');
    const [description, setDescription] = useState('');

    const onFormSubmit = (event: {preventDefault: () => void}) => {
        event.preventDefault();
        createApiToken(description, new Date(expirationDate).getTime()).then(([token]) => {
            if (!token) return;
            const t = token.token;
            token.fullToken = t;
            token.token = t.substring(t.length - 10, t.length);
            onCreate(token);
        });
    };

    return (
        <ListItem>
            <form onSubmit={onFormSubmit}>
                <label>Select expiration date</label>
                <Input
                    type="datetime-local"
                    onChange={(evt) => {
                        setExpirationDate(evt.target.value);
                    }}
                />
                <label>Description</label>
                <Input
                    onChange={(evt) => {
                        setDescription(evt.target.value);
                    }}
                />
                <Button sx={{marginTop: 1, width: '100%'}} onClick={onFormSubmit}>
                    Create
                </Button>
            </form>
        </ListItem>
    );
};

function TokenPage({isLoggedIn}: {isLoggedIn: boolean}) {
    const [apiTokens, setApiTokens] = useState<APIToken[]>([]);

    useEffect(() => {
        if (!isLoggedIn) return;

        getApiTokens().then(([tokens]) => {
            if (tokens) setApiTokens(tokens);
        });
    }, [isLoggedIn, setApiTokens]);
    return (
        <PageLayout title="Access Tokens">
            <List>
                <CreateTokenForm
                    onCreate={(token) => setApiTokens([...apiTokens, token])}
                />
                {apiTokens.map((token) => (
                    <ApiToken
                        token={token}
                        deleteCallback={(tokenId: string) => {
                            deleteApiToken(tokenId).then(() => {
                                const newApiTokens = apiTokens.filter(
                                    (tokenElem) => tokenElem._id !== tokenId
                                );
                                setApiTokens(newApiTokens);
                            });
                        }}
                    />
                ))}
            </List>
        </PageLayout>
    );
}

export default TokenPage;
