import {PaletteBackground, extendTheme} from '@mui/joy/styles';

declare module '@mui/joy/styles' {
    // No custom tokens found, you can skip the theme augmentation.
}

export const smTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    '50': '#faedeb',
                    '100': '#f0cac2',
                    '200': '#e6a699',
                    '300': '#dc8270',
                    '400': '#d25f47',
                    '500': '#C84B31',
                    '600': '#d25f47',
                    '700': '#662619',
                    '800': '#3d170f',
                    '900': '#140805',
                },
            },
        },
        dark: {
            palette: {},
        },
    },
});

const primary = {
    '50': '#ffe5f5',
    '100': '#ffb3e0',
    '200': '#ff80cb',
    '300': '#ff4db7',
    '400': '#ff1aa2',
    '500': '#E8008A',
    '600': '#BD006E',
    '700': '#85004D',
    '800': '#4d002e',
    '900': '#1a000f',
};

const neutral = {
    '50': '#f2f2f2',
    '100': '#e6e6e6',
    '200': '#d4d4d4',
    '300': '#c2c2c2',
    '400': '#b0b0b0',
    '500': '#9e9e9e',
    '600': '#8c8c8c',
    '700': '#7a7a7a',
    '800': '#686868',
    '900': '#565656',
};

const background: Partial<PaletteBackground> = {
    backdrop: '#101112',
    level1: '#101112',
    level2: '#101112',
    level3: '#101112',
    surface: '#101112',
    body: '#090a0b',
};

export const wlTheme = extendTheme({
    radius: {
        xs: '16px',
        sm: '24px',
        md: '32px',
        lg: '40px',
    },
    components: {
        JoyCard: {
            styleOverrides: {
                root: {
                    borderColor: '#1e1e1e',
                },
            },
        },
        JoyInput: {
            styleOverrides: {
                input: {
                    '&:focus': {
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                    },
                },
                root: {
                    backgroundColor: '#1e1e1e',
                    '&:focus': {
                        boxShadow: 'none',
                        outline: 'none',
                        border: 'none',
                    },
                    boxShadow: 'none',
                    marginBottom: '8px',
                    border: 'none',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    paddingLeft: '18px',
                    paddingRight: '18px',
                },
            },
        },
        JoyButton: {
            styleOverrides: {
                root: {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '50px',
                },
            },
        },
    },
    colorSchemes: {
        light: {
            palette: {
                primary,
                neutral,
                background,
            },
        },
        dark: {
            palette: {primary, neutral, background},
        },
    },
});
